import { jsx as _jsx } from "react/jsx-runtime";
import { Box, Card } from '@mui/material';
import Label from '../../../../components/label';
import { getMateriaux } from '../../../../redux/slices/materiaux/materiauxThunk';
import { insertDentPrice, insertDentType, setDentAsNotConfirmed, } from '../../../../redux/slices/prothese/protheseSlice';
import { dispatch, useSelector } from '../../../../redux/store';
import EMPTY from '../../../../assets/illustrations/illustration_empty_content.svg';
import { useEffect } from 'react';
export default function LeftOptionSections({ selectedDent, types, setSelectedType, selectedType, setCurrentPrice, }) {
    useEffect(() => {
        dispatch(getMateriaux({ limit: 1000 }));
    }, []);
    const data = useSelector((state) => state.type.allTypes);
    return (_jsx(Card, { sx: {
            marginTop: '15px',
            overflow: 'auto',
            width: '100%',
            maxHeight: '550px',
            background: 'transparant',
            display: 'flex',
            padding: '15px',
            justifyContent: 'start',
            alignItems: 'start',
            flexDirection: 'row',
            flexWrap: 'wrap',
            gap: '15px',
        }, children: data?.length > 0 ? (data?.map((el) => {
            return (_jsx(Label, { sx: {
                    padding: '30px 30px',
                    width: {
                        lg: '32%',
                        m: '50',
                        xs: '100%',
                    },
                    cursor: 'pointer',
                    fontSize: '90%',
                    lineHeight: '1.25 !important',
                    whiteSpace: 'wrap',
                    textAlign: 'center',
                    flexGrow: '1',
                }, color: selectedType === el?._id ? 'info' : 'default', onClick: () => {
                    dispatch(insertDentPrice({ name: selectedDent.toString(), price: Number(el?.price) }));
                    setSelectedType(el?._id);
                    setCurrentPrice(el?.price);
                    dispatch(insertDentType({ name: selectedDent.toString(), types: el?._id }));
                    dispatch(setDentAsNotConfirmed({ name: selectedDent.toString() }));
                }, children: el?.code }));
        })) : (_jsx(Box, { sx: {
                padding: '15px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
            }, children: _jsx("img", { src: EMPTY, alt: "error" }) })) }));
}
